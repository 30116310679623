import { alertController, loadingController, SpinnerTypes, AlertButton, toastController } from '@ionic/vue'

const showAlert = (header: string, message: string, buttons: (AlertButton | string)[] = ['OK'], backdropDismiss = true): Promise<HTMLIonAlertElement> => alertController.create({
  header,
  message,
  buttons,
  backdropDismiss
})

const showLoading = (spinner: SpinnerTypes = 'crescent'): Promise<HTMLIonLoadingElement> => loadingController.create({
  spinner,
  cssClass: 'custom-loading'
})

const showToast = (message: string, color: string): Promise<HTMLIonToastElement> => toastController.create({
  message,
  color,
  duration: 2000,
  cssClass: 'custom-toast'
})

export {
  showAlert,
  showLoading,
  showToast
}
