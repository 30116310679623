import { defineStore } from 'pinia'
import { State } from '@/models'
import api from '@/services/api'

const useStore = defineStore('main', {
  state: () => ({
    title: 'VD Apprentissage',
    apiUrl: process.env.VUE_APP_API_URL,
    locale: 'fr',
    messages: {},
    metiers: [],
    aides: [],
    responsables: [],
    informationsPratiques: [],
    sousInformationsPratiques: [],
    apresApprentissages: [],
    sousApresApprentissages: []
  }) as State,
  getters: {
    getMetiersBySearch: state => (metier: string) => state.metiers.filter(value => value.name.toLocaleLowerCase().includes(metier.toLocaleLowerCase())),
    getMetierById: state => (metierId: string) => state.metiers.find(value => value.id === metierId),
    getAidesByMetierId: state => (metierId: string) => state.aides.filter(value => value.metier_id === metierId),
    getResponsablesByAideId: state => (aideId: string) => state.responsables.filter(value => value.aide_id === aideId),
    getInformationsPratiquesByMetierId: state => (metierId: string) => state.informationsPratiques.filter(value => value.metier_id === metierId),
    getInformationPratiqueById: state => (informationPratiqueId: string) => state.informationsPratiques.find(value => value.id === informationPratiqueId),
    getSousInformationsPratiquesByInformationPratiqueId: state => (informationPratiqueId: string) => state.sousInformationsPratiques.filter(value => value.information_pratique_id === informationPratiqueId),
    getSousInformationPratiqueById: state => (sousInformationPratiqueId: string) => state.sousInformationsPratiques.find(value => value.id === sousInformationPratiqueId),
    getApresApprentissagesByMetierId: state => (metierId: string) => state.apresApprentissages.filter(value => value.metier_id === metierId),
    getApresApprentissageById: state => (apresApprentissageId: string) => state.apresApprentissages.find(value => value.id === apresApprentissageId),
    getSousApresApprentissagesByApresApprentissageId: state => (apresApprentissageId: string) => state.sousApresApprentissages.filter(value => value.apres_apprentissage_id === apresApprentissageId),
    getSousApresApprentissageById: state => (sousApresApprentissageId: string) => state.sousApresApprentissages.find(value => value.id === sousApresApprentissageId)
  },
  actions: {
    async fetchMessages () {
      const { data } = await api.get(`items/messages?filter[locale][_eq]=${this.locale}`)
      this.messages = data.data[0].value
    },
    async fetchMetiers () {
      const { data } = await api.get('items/metiers?sort[]=name&limit=1000')
      this.metiers = data.data
    },
    async fetchAides () {
      const { data } = await api.get('items/aides?sort[]=order&limit=1000')
      this.aides = data.data
    },
    async fetchResponsables () {
      const { data } = await api.get('items/responsables?sort[]=order&limit=1000')
      this.responsables = data.data
    },
    async fetchInformationsPratiques () {
      const { data } = await api.get('items/informations_pratiques?sort[]=order&limit=1000')
      this.informationsPratiques = data.data
    },
    async fetchSousInformationsPratiques () {
      const { data } = await api.get('items/sous_informations_pratiques?sort[]=order&limit=1000')
      this.sousInformationsPratiques = data.data
    },
    async fetchApresApprentissages () {
      const { data } = await api.get('items/apres_apprentissages?sort[]=order&limit=1000')
      this.apresApprentissages = data.data
    },
    async fetchSousApresApprentissages () {
      const { data } = await api.get('items/sous_apres_apprentissages?sort[]=order&limit=1000')
      this.sousApresApprentissages = data.data
    }
  }
})

export default useStore
